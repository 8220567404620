























































// eslint-disable-next-line import/extensions
import { UploadsResponse, UploadListing } from '@/interfaces/main.d.ts'
import Urls from '@/utilities/Url'
import Errors from '@/utilities/Errors'
import AddFolder from '@/components/Uploads/AddFolder.vue'

export default {
  name: 'AddPhotoUploadsList',
  components: {
    AddFolder,
  },
  data() {
    return {
      folderName: null,
      folderId: null,
      loading: false,
      status: null,
      selectedItem: -1,
      items: [] as UploadListing[],
    }
  },
  computed: {
    title(): string {
      let title: string
      switch (this.status) {
        case 'show-folders': title = 'Select Folder to Add Photos:'; break
        case 'selected-folder': title = 'Add photos to folder:'; break
        case 'new-folder': title = 'Add photos to folder:'; break
        default: title = 'Select Folder to Add Photos:'
      }
      return title
    },
  },
  beforeMount(): void {
    this.loadFolders()
      .then(() => {
        this.status = (this.items && this.items.length > 0) ? 'show-folders' : 'new-folder'
        this.$emit('loading', false)
      })
      .catch(() => {
        this.$emit('loading', false)
      })
  },
  methods: {
    uploadPath(upload: UploadListing) {
      return `/upload/${upload.upload_id}`
    },
    /**
     * Load exiting folders
     */
    loadFolders(): Promise<boolean> {
      return new Promise((resolve, reject) => {
        this.$emit('loading', true)
        fetch(Urls.url('uploads'), {
          method: 'GET',
          headers: { Authorization: localStorage.tk },
        }).then((response: Response) => response.json())
          .then((folders: UploadsResponse) => {
            if (folders.status !== 'Success') {
              reject(folders.message)
            } else {
              this.items = folders.data || []
              resolve(true)
            }
          })
          .catch((err: string) => {
            Errors.logError(err)
            reject()
          })
      })
    },
    onNewFolder(): void {
      this.status = 'new-folder'
      this.folderName = null
      this.folderId = -1
    },
    selectFolder(folder): void {
      this.status = 'selected-folder'
      this.folderName = folder.name
      this.folderId = folder.upload_id
      this.$emit('change', this.folderId, this.folderName)
    },
    onCancelNewFolder(): void {
      this.status = 'show-folders'
      this.folderName = null
      this.radioGroup = -2
      this.$emit('change', null)
    },
    childLoading(status: boolean): void {
      this.$emit('loading', status)
    },
    onCreateNewFolder(id: string, name: string): void {
      this.status = 'selected-folder'
      this.folderName = name
      this.folderId = id
      this.$emit('change', this.folderId, this.folderName)
    },
  },
}
