























/**
 * This component presents the user with a button that opens the file selection
 * modal, used select photos on the device for upload.
 *
 * Selected filenames are stored in an UploadQueue and persisted in vuex using
 * the changeActivityQueue method.
 *
 * Props:
 * uploadId the upload folder ID that is targeted for the selected pics
 *
 * Events:
 * change ()  conveys that files have been selected
 *
 * APIs:
 * None
 */

// eslint-disable-next-line import/extensions
import { UploadQueue, UploadQueuePics } from '@/interfaces/main.d.ts'

const SELECT_READY = 'green lighten-2'
const SELECT_COMPLETE = 'grey lighten-2'

export default {
  name: 'SelectPhotos',
  data() {
    return {
      loading: false,
      buttonColor: SELECT_READY,
      files: null,
      cnt: null,
    }
  },
  props: {
    uploadId: {
      type: String,
      required: true,
    },
    folderName: {
      type: String,
      required: true,
    },
  },
  methods: {
    doClick(): void {
      if (this.files && this.files.length > 0) {
        const pics: UploadQueuePics = {}
        let key = 1
        this.files.forEach((file) => {
          pics[String(key)] = file
          key += 1
        })

        const queue: UploadQueue = {
          // eslint-disable-next-line @typescript-eslint/camelcase
          upload_id: this.uploadId,
          name: this.folderName,
          count: this.files.length,
          pics,
        }

        this.$store.commit('changeActivityQueue', queue)
        this.$uploader.start(this.$store)
        this.buttonColor = SELECT_COMPLETE
        this.$emit('change')
      } else {
        this.$store.commit('changeActivityQueue', null)
        this.buttonColor = SELECT_READY
      }
    },
  },
}
