
































/**
 * This component is used capture a new upload folder name and created it on the server.
 *
 * Folder names must be at least 3 characters.
 *
 * Props:
 * None
 *
 * Events:
 * loading (boolean)  conveys to the parent the component is waiting for a server response
 * create (string, string)  conveys the new folder is created, parameters: folder id, folder name
 * cancel ()  conveys the user does not want to create a new folder
 *
 * APIs:
 * createUpload: POST   sends a payload to create a new upload folder
 */

// eslint-disable-next-line import/extensions
import { UploadCreateResponse } from '@/interfaces/main.d.ts'
import Urls from '@/utilities/Url'
import Errors from '@/utilities/Errors'

interface FolderPromise {
  id: string;
  name: string;
}

export default {
  name: 'AddFolder',
  data() {
    return {
      folderName: null,
    }
  },
  computed: {
    saveEnabled(): boolean {
      return this.folderName && this.folderName.length > 2
    },
  },
  methods: {
    onSave(): void {
      this.createFolder()
        .then((data: FolderPromise) => {
          this.$emit('loading', false)
          this.$emit('create', data.id, data.name)
        })
        .catch(() => {
          this.$emit('loading', false)
        })
    },
    onCancel(): void {
      this.$emit('cancel')
    },
    /**
     * Send payload to create new folder
     */
    createFolder(): Promise<FolderPromise> {
      return new Promise((resolve, reject) => {
        this.$emit('loading', true)
        const body: string = JSON.stringify({
          name: this.folderName,
        })
        fetch(Urls.url('createUpload'), {
          method: 'POST',
          headers: { Authorization: localStorage.tk },
          body,
        }).then((response: Response) => response.json())
          .then((folder: UploadCreateResponse) => {
            if (folder.status !== 'Success') {
              reject(folder.message)
            } else {
              resolve({ id: folder.data.id, name: this.folderName })
            }
          })
          .catch((err: string) => {
            Errors.logError(err)
            reject()
          })
      })
    },
  },
}
