




















































import Display from '@/utilities/Display'
import InsideContainer from '@/components/Containers/InsideContainer.vue'
// import UploadFolders from '@/components/Uploads/UploadFolders.vue'
import SelectPhotos from '@/components/Uploads/SelectPhotos.vue'
import Loading from '@/components/Modals/Loading.vue'
import UploadActivity from '@/components/Uploads/UploadActivity.vue'
import AddPhotoUploadsList from '@/components/Lists/AddPhotoUploadsList.vue'

export default {
  name: 'AddPhotos',
  components: {
    InsideContainer,
    // UploadFolders,
    SelectPhotos,
    Loading,
    UploadActivity,
    AddPhotoUploadsList,
  },
  data() {
    return {
      show: false,
      name: 'click',
      showPicSelect: false,
      showUploadBtn: false,
      showActivity: false,
      childLoaded: false,
      uploadId: null,
      folderName: null,
    }
  },
  computed: {
    mobile(): boolean { return Display.mobile(this.$vuetify) },
  },
  beforeMount(): void {
    this.showActivity = (this.$store.state.activityQueue !== null)
  },
  methods: {
    childLoading(loading: boolean): void {
      this.childLoaded = loading
    },
    onFolderChange(uploadId: string, folderName: string): void {
      this.uploadId = uploadId
      this.folderName = folderName
      this.showPicSelect = (uploadId)
    },
    onPhotosChange(): void {
      this.showUploadBtn = (this.$store.state.activityQueue !== null)
      this.showPicSelect = false
      this.showActivity = true
    },
  },
}
