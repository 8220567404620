


























































/**
 * This component displays pics queued for upload. The queue is in local storage.
 *
 * Props:
 * None
 *
 * Events:
 * change ()  conveys that files have been selected
 *
 * APIs:
 * None
 */

// eslint-disable-next-line import/extensions
import { UploadQueue, VuexState } from '@/interfaces/main.d.ts'
// eslint-disable-next-line import/extensions
import Errors from '@/utilities/Errors'

export default {
  name: 'UploadActivity',
  data() {
    return {
      uploadId: null,
      uploadQueue: null,
      uploadingFile: null,
      uploadingFilename: null,
      unsubscribeFn: null,
      files: null,
      folderName: null,
      totalFiles: 0,
    }
  },
  computed: {
    remaining(): number {
      return (this.totalFiles && this.files && this.files.length)
        ? this.totalFiles - this.files.length
        : 0
    },
  },
  created(): void {
    this.unsubscribeFn = this.$store.subscribe((mutation) => {
      if (mutation.type === 'changeUploadingFile') {
        this.refresh()
      }
    })
  },
  destroyed(): void {
    this.unsubscribeFn()
  },
  beforeMount(): void {
    this.refresh()
  },
  methods: {
    refresh(): void {
      this.loadLoadingFile()
      this.loadQueueFiles()
    },
    /**
     * Read in the file currently be uploaded from vuex
     */
    loadLoadingFile(): void {
      try {
        if (!this.$store.state.uploadingFile) {
          this.uploadingFile = null
          this.uploadingFilename = null
        } else {
          this.uploadingFilename = this.$store.state.uploadingFile.name
          const reader = new FileReader()
          reader.readAsDataURL(this.$store.state.uploadingFile)
          // closure function providing the filename when the file is read into memory
          const loadHandler = (event) => {
            this.uploadingFile = event.target.result
          }
          reader.onload = loadHandler
        }
      } catch (err) {
        Errors.logError('Unable to load activityQueue from state, in SelectPhotos.refresh()')
        Errors.logError(err)
        Errors.logError('Wiping out activityQueue from state')
      }
    },
    /**
     * Read in the upload file queue from vuex
     */
    loadQueueFiles(): void {
      try {
        this.uploadingFile = (this.$store.state.uploadingFile)
          ? this.$store.state.uploadingFile
          : null

        if (this.$store.state.activityQueue) {
          const queue: UploadQueue = this.$store.state.activityQueue
          this.uploadId = queue.upload_id
          this.uploadQueue = queue.pics
          this.totalFiles = queue.count
          this.folderName = queue.name
          this.files = []
          Object.keys(this.uploadQueue).forEach((key) => {
            const reader = new FileReader()
            reader.readAsDataURL(this.uploadQueue[key])
            // closure function providing the filename when the file is read into memory
            const loadHandler = (event) => {
              const { name } = this.uploadQueue[key]
              this.files.push({ name, target: event.target.result })
            }
            reader.onload = loadHandler
          })
        }
      } catch (err) {
        Errors.logError('Unable to load activityQueue from state, in SelectPhotos.refresh()')
        Errors.logError(err)
        Errors.logError('Wiping out activityQueue from state')
      }
    },
  },
}
